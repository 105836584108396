.main {
  .wrapper {
    padding-bottom: 25px;
  }

  @include wide-mobile {
    .wrapper {
      padding-bottom: 15px;
    }
  }
}

.main__breadcrumbs {
  margin-bottom: 25px;

  @include wide-mobile {
    margin-bottom: 10px;
  }

  li {
    display: inline-block;
    position: relative;

    &:not(:first-of-type) {
      margin-left: 15px;
    }

    &:not(:last-of-type)::after {
      content: ">";
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      color: #54b8ea;
      margin-left: 15px;
    }

    @include wide-mobile {
      &:not(:first-of-type) {
        margin-left: 0;
      }

      &:not(:last-of-type) {
        display: none;
      }
    }
  }

  a {
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    color: #54b8ea;
    position: relative;
    display: inline-block;

    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      background: #54b8ea;
      height: 1px;
      content: "";
      bottom: -8px;
      transform: scale(0);
      transition: all 0.3s;
    }

    &:hover::after {
      transform: scale(1);
    }
  }
}

.main__columns {
  font-size: 0;
  margin-bottom: 50px;

  @include wide-mobile {
    margin-bottom: 20px;
  }
}

.main__column {
  display: inline-block;
  width: 50%;
  vertical-align: top;

  img {
    margin: 0 auto;
  }

  &--left {
    float: left;
  }

  &--right {
    float: right;
  }

  @include tablet {
    width: 100%;
    display: block;
  }
}

.main__title {
  font-weight: bold;
  font-size: 34px;
  line-height: 50px;
  color: #000000;

  @include wide-mobile {
    font-size: 24px;
    line-height: 34px;
  }

  span {
    color: red;
  }
}

.main__stat-item {
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  display: inline-block;
  margin-right: 25px;

  @include wide-mobile {
    font-size: 14px;
    line-height: 16px;
  }

  &::before {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  &:nth-child(1)::before {
    content: url("../img/main__eye.png");
  }

  &:nth-child(2)::before {
    content: url("../img/main__update.png");
  }
}

.main__img {
  margin: 0 auto;
  margin-bottom: 25px;

  @include wide-mobile {
    margin-bottom: 5px;
  }
}

.btn {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  background: #ff0a0a;
  border-radius: 50px;
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  padding: 11px 0;
  transition: all 0.3s;
  max-width: 580px;
  margin: 0 auto;

  @include wide-mobile {
    font-size: 20px;
    line-height: 20px;
    padding: 15px 0;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.link {
  color: #ff0a0a;
  position: relative;
  font-weight: bolder;

  &::after {
    position: absolute;
    left: 0;
    content: "";
    bottom: 0px;
    width: 100%;
    height: 2px;
    background: #ff0a0a;
    transform: scale(0);
    transition: all 0.3s;
  }

  &:hover {
    &::after {
      transform: scale(1);
    }
  }
}

.main__promo-text {
  border-left: 4px solid #54b8ea;
  padding: 10px 30px;
  margin-bottom: 25px;

  @include tablet {
    padding: 10px;
  }

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #000000;
    margin-top: 25px;

    @include wide-mobile {
      font-size: 22px;
      line-height: 30px;
      margin-top: 15px;
    }
  }
}

.main__promo-avatar {
  float: left;
  width: 60px;
  margin-right: 10px;

  @include wide-mobile {
    width: 40px;
    height: 40px;
  }
}

.main__promo-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  display: table-cell;
  height: 60px;
  vertical-align: middle;

  @include wide-mobile {
    font-size: 22px;
    line-height: 26px;
  }

  span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;

    @include wide-mobile {
      font-size: 22px;
      line-height: 24px;
      display: block;
    }
  }
}

.main__title-question {
  background: #f4f4f4;
  padding: 15px 40px;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #3666a4;
  margin-bottom: 50px;
  position: relative;

  @include wide-mobile {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 15px;
    padding: 10px;
    padding-top: 20px;
  }

  &::before {
    position: absolute;
    content: "";
    left: 30px;
    top: -10px;
    width: 32px;
    height: 26px;
    background: url("../img/main__quotes.png") no-repeat center / contain;
  }
}

.main__blank-text {
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 30px;

  @include wide-mobile {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.main__question {
  background: #f4f4f4;
  padding: 15px 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3666a4;
  margin-bottom: 50px;
  position: relative;

  @include wide-mobile {
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
    margin-bottom: 15px;
  }
}

.main__doc {
  border-left: 4px solid #54b8ea;
  padding: 0 30px;
  margin-bottom: 25px;

  @include wide-mobile {
    padding: 0 10px;
  }
}

.main__doc-avatar {
  float: left;
  width: 60px;
  margin-right: 10px;

  @include wide-mobile {
    width: 40px;
    height: 40px;
  }
}

.main__doc-info {
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
  display: table-cell;
  height: 60px;
  vertical-align: middle;

  @include wide-mobile {
    height: 40px;
    font-size: 20px;
    line-height: 23px;
  }

  span {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
  }
}

.main__author-info {
  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
}

.main__prod {
  margin: 0 auto;
  margin-bottom: 25px;

  @include wide-mobile {
    max-width: 150px;
  }
}
