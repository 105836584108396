.comments {
  padding-top: 25px;

  .wrapper {
    max-width: 780px;
  }
}

.comments__title {
  background: #136fa2;
  font-weight: 500;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 35px;
  color: #ffffff;
  padding-left: 20px;
}

.comments__item {
  margin-bottom: 25px;
  border-bottom: 1px solid #54B8EA;
  padding-left: 100px;
  position: relative;
  padding-bottom: 15px;

  @include wide-mobile {
    padding-left: 0;
  }
}

.comments__ava-img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
  position: absolute;
  border-radius: 50%;
  left: 0;
  top: 0;

  @include wide-mobile {
    width: 50px;
    height: 50px;
    position: static;
    float: left;
  }
}

.comments__name {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;

  @include wide-mobile {
    line-height: 50px;
  }
}

.comments__text {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  clear: both;
}

.comments__date {
  font-style: italic;
  font-weight: 300;
  font-size: 16px;

  @include wide-mobile {
    display: block;
  }
}
