.header__inner {
  display: table;
  width: 100%;
  padding: 15px 0;
}

.header__cell {
  display: table-cell;
  vertical-align: middle;

  @include wide-mobile {
    width: 50%;
  }

  &--right {
    @include wide-mobile {
      text-align: right;
    }
  }
}

.header__logo {
  display: inline-block;
}

.header__logo-img {
  width: 410px;

  @include tablet {
    max-width: 250px;
  }

  @include mobile {
    width: 200px;
  }
}

$btn-width: 40px;
$btn-width--mob: 35px;
$btn-bar-height: 3px;
$btn-margin: 10px;

.header__menu-btn {
  width: $btn-width;
  height: $btn-bar-height;
  position: relative;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 50px;

  @include wide-mobile {
    width: $btn-width--mob;
    margin-right: 25px;
  }

  span {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    height: $btn-bar-height;
    background: #000000;

    @include wide-mobile {
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: $btn-bar-height;
      background: #000000;
    }

    &::before {
      top: $btn-margin;
      left: 0;
    }

    &::after {
      bottom: $btn-margin;
      left: 0;
    }
  }
}

.header__input-wrapper {
  width: 380px;
  float: right;
  position: relative;

  @media (max-width: 820px) {
    width: 380px;
  }

  @include wide-mobile {
    width: auto;
    width: 30px;
    height: 50px;
  }
}

.header__input {
  width: 100%;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  padding: 12.5px 10px 12.5px 50px;
  color: #717171;
  border: none;
  background:  url('../img/header__search-icon.png') no-repeat left 10px center / 25px auto, #F4F4F4;

  @include tablet {
  }

  @include wide-mobile {
    width: 100%;
    height: 100%;
    background: url("../img/header__input-icon.png") no-repeat center;
    padding: 0;
    border: none;
    font-size: 0;
  }
}
