$mobile-width: 480px;
$tablet-width: 768px;
$laptop-width: 992px;
$desktop-width: 1200px;
$desktop-full: 1600px;
$desktop-wide: 1920px;
$wrapper-width: 1020px;

$mobile-width-only: 767px;
$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;

// Шрифты
$font1: "Roboto", sans-serif;

// Цвета
$color-back: #f5f2e9;
$color-red: #e51414;
$color-yellow: #f2c215;
$main-color: #34495e;
